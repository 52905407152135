import axios from "axios"

import { Notification } from "components/Common/Notification"
import {
  useGetRole,
  useGetToken,
  useRemoveSession,
} from "hooks/useHandleSessions"
// import cookieClient from 'react-cookie'

//apply base url for axios
// const API_URL_LOCAL = "http://192.168.1.2:8000"
const API_URL_PROD = "https://api.khaf.in"

const API_URL_DEV = "https://api-dev.khaf.in"

export const API_URL = API_URL_DEV
export const TOKEN_PREFIX = "token"

export const axiosApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

// axiosApi.defaults.withCredentials = true

axiosApi.interceptors.request.use(
  function (config) {
    const { token } = useGetToken()
    const { role } = useGetRole()
    if (token) {
      config.headers["Authorization"] = "token " + token
    }
    if (role && role !== "") {
      config.headers["Role"] = role
    }
    return config
  },
  function () {}
)

// ...
// const cookie = cookieClient.load('sessionid')
// if(cookie === undefined){
//       axios.get('path/to/my/cookie/api').then(response => {
//         if(response.status == 200){
//           cookieClient.save('sessionid', response.data, {path:'/'})
//         }
//       })
//     }
// ...

axiosApi.interceptors.response.use(
  response => {
    // console.log(response)
    // if (response.status == 200) {
    //   cookieClient.save('sessionid', response.data, { path: '/' })
    // }
    return response
  },
  err => {
    if (err.response && err.response.status && err.response.status == 401) {
      useRemoveSession("token")
      window.location.reload(false)
      Notification({
        type: "error",
        message: err?.response?.data?.detail,
        title: err?.response?.statusText,
      })
    } else {
      Notification({
        type: "error",
        message: err?.response?.data?.detail,
        title: err?.response?.statusText,
      })
    }
    return err.response
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function ApiPut(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
